// src/pages/BrowsingPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchLiveFiles, processFile, downloadFile,fetchLiveFolderName } from '../services/api';
import Breadcrumb from '../components/Breadcrumb';
import { ClipLoader } from 'react-spinners'; // Import a spinner library (like react-spinners or any other)
import { useFolderHierarchy } from '../context/FolderHierarchyContext';
import FileUploader from '../components/FileUploader';
const placeholderImage = 'https://via.placeholder.com/180';

export default function LivePage({ user }) {
  const { folderId = 'root' } = useParams();  
  const navigate = useNavigate();
  // Use the global folder hierarchy from context
  const { folderPath, setFolderPath } = useFolderHierarchy();

  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [rootFolder, setRootFolder] = useState('root');

  // const [folderPath, setFolderPath] = useState([{ id: 'root', name: 'Root' }]);

  useEffect(() => {
    const updateGlobalBreadcrumb = async (folderId) => {
      if (folderId === 'root') {
        setFolderPath([{ id: 'root', name: 'Root' }]);
        document.title = 'Live Browse';
      } else {
        try {
          // Fetch the folder name using your API
          const name = (await fetchLiveFolderName(folderId)).data.name;
          document.title = name;
          setFolderPath(prevPath => {
            // If folder already exists (backward navigation), slice to that folder.
            const existingIndex = prevPath.findIndex(folder => String(folder.id) === String(folderId));
            if (existingIndex !== -1) {
              return prevPath.slice(0, existingIndex + 1);
            }
            // Otherwise, append it.
            return [...prevPath, { id: folderId, name }];
          });
        } catch (err) {
          console.error('Error fetching folder path', err);
        }
      }
    };

    updateGlobalBreadcrumb(folderId);
    if(rootFolder === 'root') setRootFolder(folderId);

    // Fetch files and folders for the current folderId.
    fetchLiveFiles(folderId)
      .then(response => {
        const data = response.data;
        const folderItems = data.filter(
          item => item.mimeType === 'application/vnd.google-apps.folder'
        );
        const fileItems = data.filter(
          item => item.mimeType !== 'application/vnd.google-apps.folder'
        );

        setFolders(folderItems);
        setFiles(fileItems);

        // For the "root" folder, we want to reset the breadcrumb.
        if (folderId === 'root') {
          setFolderPath([{ id: 'root', name: 'Root' }]);
        }
      })
      .catch(err => console.error('Error fetching files', err));
  }, [folderId]);

  const handleFolderClick = (folderId, folderName) => {
    // if(rootFolder === 'root'){
    //   setRootFolder(folderId);
    // }
    setFolderPath(prevPath => [...prevPath, { id: folderId, name: folderName }]);
    document.title = folderName;
    navigate(`/live/${folderId}`);
  };

  const handleBreadcrumbClick = (index) => {
    // The user clicked on some crumb in the path
    const newPath = folderPath.slice(0, index + 1);
    setFolderPath(newPath);
    document.title = newPath[newPath.length - 1].name;
    navigate(`/live/${newPath[newPath.length - 1].id}`);
  };

  const handleProcessFile = (fileId) => {
    // Set loading indicator for that file
    setFiles(prev =>
      prev.map(file => (file.id === fileId ? { ...file, loading: true } : file))
    );
    processFile(fileId)
      .then(response => {
        // Mark loading = false and store "result" (the public URL)
        const url = response.data.result;
        setFiles(prev => prev.map(file =>
          file.id === fileId ? { ...file, loading: false, result: url } : file
        ));
      })
      .catch(err => {
        console.error('Error processing file:', err);
        setFiles(prev => prev.map(file =>
          file.id === fileId ? { ...file, loading: false } : file
        ));
      });
  };

  const handleDownloadFile = async (fileId) => {
    // Option 1: Directly open the returned URL
    const downloadLink = await downloadFile(fileId);
    // window.open(downloadLink, '_blank');
      // Create a link element
      const link = document.createElement('a');
      link.href = downloadLink;
      // link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.setAttribute('download', '');
  
      // Append link to body and trigger click to initiate download
      document.body.appendChild(link);
      link.click();
      
      // Clean up by removing the link element
      document.body.removeChild(link);
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column p-4 bg-light max-w-6xl">
      <div className="align-self-start">
        <Breadcrumb folderPath={folderPath} onNavigate={handleBreadcrumbClick} />
      </div>
      {/* <FileUploader folderId={folderId} /> */}

      <div className="d-flex justify-content-center">
        <button className="btn btn-outline-danger mb-2" onClick={() => navigate(`/browse/${rootFolder}`)}>
            Switch to <b><i>Gallery View</i></b>
        </button>
      </div>

      {/* Folders Section */}
      {/* {folders.length > 0 && <h3>Folders</h3>} */}
      <div className="row">
        {folders.map(folder => (
          <div key={folder.id} className="col-md-3 p-2">
            <div 
              className="card text-center p-3 shadow-sm hover-shadow cursor-pointer"
              onClick={() => handleFolderClick(folder.id, folder.name)}
            >
              <span className="text-primary">{folder.name}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Files Section */}
      <div className="row row-cols-3 gx-2 gy-2">
        {files.map(file => (
          <div key={file.id}>
            <div className="card shadow-sm">
              <img
                src={file.thumbnail || placeholderImage}
                alt={file.name}
                className="card-img-top"
                style={{ height: '240px', objectFit: 'cover',width: '100%' }}
              />
              <div className="card-body">
                <h6 className="card-title d-flex align-items-center">
                  {/* “Process” button */}
                  <button 
                    onClick={() => handleProcessFile(file.id)}
                    className="btn btn-light mr-1"
                  >
                    🔗
                  </button>

                  {/* Download button */}
                  <button 
                    onClick={() => handleDownloadFile(file.id)}
                    className="btn btn-light"
                  >
                    📥
                  </button>

                  {/* File name */}
                  <span className="text-truncate flex-grow-1 ms-2">{file.name}</span>
                </h6>
                
                {/* File loading spinner or result */}
                <p class="card-text">{file.loading && (
                      <div className="d-flex justify-content-center">
                        <ClipLoader size={20} color={"#123abc"} loading={true} />
                      </div>
                    )}
                    {file.result && (
                      <div>
                         <button
                          onClick={() => navigator.clipboard.writeText(file.result)}
                          className="btn btn-light align-middle mr-2"
                        >
                          📋
                        </button>
                        
                        <span className="text-success text-truncate d-inline-block w-75 align-middle">{file.result}</span>
                       
                      </div>
                    )}
                    </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
