import axios from 'axios';

const backendUrl = "https://drive-navigator-914312986344.us-central1.run.app";
// const backendUrl = "http://localhost:5000";

const api = axios.create({
  baseURL: backendUrl,
  withCredentials: true, 
});

export async function getProfile() {
  return api.get('/auth/profile');
}

export async function fetchFiles(folderId = 'root') {
  return api.get(`/api/files?folderId=${folderId}`);
}

export async function fetchLiveFiles(folderId = 'root') {
  return api.get(`/api/live-files?folderId=${folderId}`);
}

export async function uploadFile(formData,folderId){
  return api.post('/api/upload-file', formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function toggleWeeklyScan(folderId, status) {
  return api.post('/api/toggle-scan', { folderId, status });
}

export async function triggerScan(folderId) {
  return api.post('/api/trigger-scan', { folderId });
}

export async function downloadFile(fileId) {
  // This returns a download URL or triggers the download directly
  // Typically, you would just open the download link from the component, 
  // but you can place any special logic here if needed.
  return `${backendUrl}/api/download-file?fileId=${fileId}`;
}

export async function downloadZippedFiles(urls) {
  const form = new FormData();
  form.append("imageUrls", JSON.stringify(urls));

  const tempForm = document.createElement("form");
  tempForm.method = "POST";
  tempForm.action = `${backendUrl}/api/download-images`;
  tempForm.style.display = "none";

  const input = document.createElement("input");
  input.type = "hidden";
  input.name = "imageUrls";
  input.value = JSON.stringify(urls);

  tempForm.appendChild(input);
  document.body.appendChild(tempForm);
  tempForm.submit();
  document.body.removeChild(tempForm);
}

export async function fetchFolderName(folderId){
  return api.get(`/api/folder-name?folderId=${folderId}`);
}

export async function fetchLiveFolderName(folderId){
  return api.get(`/api/live-folder-name?folderId=${folderId}`);
}

export async function processFile(fileId) {
  return api.post('/api/process-file', { fileId });
}

export function loginUrl() {
  return `${backendUrl}/auth/google`;
}

export function logoutUrl() {
  return `${backendUrl}/auth/logout`;
}

export default api;
