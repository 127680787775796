import { useState } from "react";
import { downloadZippedFiles } from "../services/api";

export default function ImageCompressorModal({ show, onClose }) {
  const [imageUrls, setImageUrls] = useState("");
  const [error, setError] = useState("");

  const handleDownload = async () => {
    setError("");

    const urls = imageUrls.split("\n").map(url => url.trim()).filter(url => url);

    if (urls.length === 0) {
      setError("Please enter at least one image URL.");
      return;
    }


    try {

       downloadZippedFiles(urls);
      // // Create a link element
      // const link = document.createElement('a');
      // link.href = downloadLink;
      // // link.target = '_blank';
      // link.rel = 'noopener noreferrer';
      // link.setAttribute('download', '');
  
      // // Append link to body and trigger click to initiate download
      // document.body.appendChild(link);
      // link.click();
      
      // // Clean up by removing the link element
      // document.body.removeChild(link);
  
    } catch (err) {
      setError("An error occurred while processing images.");
      console.error("Download error:", err);
    }

  };

  
  return (
    <div className={`modal fade ${show ? "show d-block" : "d-none"}`} tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Download Images as ZIP</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <textarea
              className="form-control"
              rows="5"
              placeholder="Enter image URLs (one per line)"
              value={imageUrls}
              onChange={(e) => setImageUrls(e.target.value)}
            ></textarea>
            {error && <div className="alert alert-danger mt-2">{error}</div>}
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={onClose}>Close</button>
            <button
              className="btn btn-primary"
              onClick={handleDownload}
              disabled={!imageUrls.trim()}
            > Download ZIP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
