// src/components/NavigationBar.js
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { logoutUrl,loginUrl } from '../services/api';
import ImageCompressorModal from "./ImageCompressorModal";

export default function NavigationBar({ user }) {  
  const [showModal, setShowModal] = useState(false);

  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">Image Navigator</Link>

        <div className=" navbar-collapse" id="navbarNav">
          {user && (
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link to="/browse" className="nav-link">Gallery Browse</Link>
              </li>
              <li className="nav-item">
                <Link to="/live" className="nav-link">Folder Browse</Link>
              </li>
              <li className="nav-item">
                <Link to="/config" className="nav-link">Configuration</Link>
              </li>
              <li className="nav-item">
                <button className="btn btn-outline-light ms-3" onClick={() => setShowModal(true)}>
                  Bulk Image URL Download
                </button>
              </li>
            </ul>
          )}
          <ul className="navbar-nav ms-auto">
            {user ? (
              <li className="nav-item">
                <a className="nav-link" href={logoutUrl()}>
                  Logout
                </a>
              </li>
            ) : (
              <li className="nav-item">
                <a className="nav-link" href={loginUrl()}>Login</a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
    {showModal && <ImageCompressorModal show={showModal} onClose={() => setShowModal(false)} />}
    </>
  );
}
